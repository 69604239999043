
import { Component, Vue } from "vue-property-decorator";
import { BASE_API_URL } from "../../../config";
import AgentContact from "../../../components/super-admin/agent/AgentContact.vue";
import Axios from "axios";
import { authHeader } from "../../../services/auth";
import { AgentInfo } from "../../../models/agent/agent.model";

@Component({
  components: {
    AgentContact,
  },
})
export default class Agent extends Vue {
  public agentList = [];
  public agentTypes: any = [];
  public selectType = 0;
  public agentName: any;
  public agentInfo = new AgentInfo();
  public isEdit = false;
  public isHide = true;
   public error = false;
  public errormsg = '';
  public stateAndCounty = [];
  public agentId: any = null;

  public async getAgentList() {
    try {
      const response = await Axios.get(`${BASE_API_URL}agent/getAgentList`, {
        headers: authHeader(),
      });
      if (response.status === 201) {
        this.agentList = response.data ? response.data : [];
      }
      
    } catch (error) {
      console.log(error);
    }
  }
  public async getAgentTypes() {
    try {
      const response = await Axios.get(
        `${BASE_API_URL}agent/getAgentTypeName`,
        {
          headers: authHeader(),
        }
      );
      if (response.status === 201) {
        this.agentTypes = response.data ? response.data : [];
        this.agentTypes = this.agentTypes.filter(a => a.id != 10);
      }
      
    } catch (error) {
      console.log(error);
    }
  }

  public async getSpecificAgents() {
    try {
      const response = await Axios.get(
        BASE_API_URL + "agent/getSpecificAgents/" + this.selectType,
        {
          headers: authHeader(),
        }
      );
      if (response.status === 201) {
        this.agentName = response.data;
        this.agentList = response.data ? response.data : [];
        if(this.selectType == 0){
          await this.getAgentList()
          }
        
      }
    } catch (error) {
      console.log(error);
    }
  }

  public async saveAgent() {
    try {
      if(this.selectType == 0 && !this.agentId){
this.error = true;
this.errormsg = "Please Select Agent Type"

    return;
      }
    
      this.agentInfo.type = this.agentId;
      let agentId
      if(!this.isEdit){
        agentId = 'new'
      }
      
      const response = await Axios.post(
        BASE_API_URL + "agent/saveAgent",
        {...this.agentInfo,agentId},
        {
          headers: authHeader(),
        }
      );
      if (response.status == 201) {
        this.$snotify.success(
          this.isEdit ? "Updated Sucessfully" : "Saved Successfully"
        );
        this.$modal.hide("addAgent");
        await this.getAgentList();
      }
    } catch (error) {
      console.log(error);
    }
  }

  public openAgentModal() {
    try {
      this.$modal.show("addAgent");
      this.isEdit = false;
      this.agentInfo = new AgentInfo();
    } catch (error) {
      console.log(error);
    }
  }

  public getTypeName(TypeName) {
    this.agentTypes.map((e: any) => {
      if (e.name === TypeName) {
        this.agentId = e.id;
      }
    });
  }

  cancel() {
    this.$modal.hide("addAgent");
  }
  public async getStatesAndCounty() {
      try {
        const response = await Axios.get(
          BASE_API_URL + "common/getStatesAndCounty"
          );
        this.stateAndCounty = response.data; 
         
      } catch (error) {
        console.log(error);
      }
    }
  async mounted() {
    await this.getAgentTypes();
    await this.getAgentList();
    await this.getStatesAndCounty();
  }
}
