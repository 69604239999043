
  import { Component, Vue } from "vue-property-decorator";
  import Axios from "axios";
  import { BASE_API_URL } from "../../../config";
  import { authHeader } from "../../../services/auth";
  import { AgentContactInfo } from "../../../models/agent/agent.model";

  @Component
  export default class AgentContact extends Vue {
    /* eslint-disable */
    public contactList = [];
    public isEdit = false;
    public agentContactInfo = new AgentContactInfo();
    public agentList = [];

    public async getAgentContactList() {
      try {
        let response = await Axios.get(
          BASE_API_URL + "agent/getAgentContactList/" + this.$route.query.id,
          {
            headers: authHeader(),
          }
          );
        if(response.status == 201){
          this.contactList = response.data.agentContactList
          this.agentList =  response.data.agentList;
        }
        // this.contactList = response.data.length > 0 ? response.data.agentContactList : [];
        // this.agentList = response.data.length > 0 ? response.data.agentList : [];
      } catch (error) {
        console.log(error);
      }
    }

    public async saveSpecificAgentContact() {

      try {
        const response = await Axios.post(
          BASE_API_URL + "agent/saveSpecificAgentContact",
          {agent:this.agentContactInfo,
           agentId:this.$route.query.id},
           { headers: authHeader() }
           );
        if (response.status == 201) {
          this.$snotify.success("Saved Successfully");
          this.$modal.hide('addAgentContact');
          this.getAgentContactList() 
        }
      } catch (error) {
        console.log(error);
      }
    }

    public openAgentContactModal() {
      try {
        this.$modal.show("addAgentContact");
        this.agentContactInfo = new AgentContactInfo();
      } catch (error) {
        console.log(error);
      }
    }

    cancel() {
      this.$modal.hide("addAgentContact");
    }

    mounted() {
      this.getAgentContactList();
    }
    /* eslint-disable */
  }
